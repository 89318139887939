import React from "react";
import {
  mapping,
  ButtonSolid,
  IconButtonSolid,
  IconSVG,
  FormatDate,
} from "@aim-mf/styleguide";

class TaskReference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  mappingBackendToFrontEndData(task, username) {
    let frontendTaskData = {
      id: task.id,
      category: task.to.includes(username)
        ? "inbox"
        : task.task_from === username
        ? "pending"
        : "error",
      type: task.alert_id ? "alert" : "task",
      title: task.title,
      description: task.description,
      date: "08/03/2020",
      dueDate: task.due_date,
      status: task.status,
      from: task.task_from,
      to: task.to,
      commentDraft: "",
      comments: [],
      actions: [],
      attachments: task.attachments,
      alert_id: task.alert_id,
      removeTaskAttachmentID: [],
    };
    task.actions.forEach((action) => {
      frontendTaskData.actions.push({
        actionID: action.action_id,
        actionType: action.action_type,
        actionContent: [
          {
            type: action.action_subtype,
            question: action.action_question,
            answer: action.action_answer,
            options: action.action_options,
            selected: action.action_choices,
            attachments: action.attachments,
            removeActionAttachmentID: [],
          },
        ],
      });
    });
    task.task_comment.forEach((comment) => {
      frontendTaskData.comments.push({
        commentText: comment.content,
        dateTime: comment.time,
        userDisplay: this.props.userList[comment.username],
        userName: comment.username,
      });
    });
    return frontendTaskData;
  }
  render() {
    if (!this.props.task || this.props.task.length === 0) {
      return <div />;
    }
    let taskList = [];
    this.props.task.forEach((taskItem) => {
      taskList.push(
        this.mappingBackendToFrontEndData(
          taskItem,
          this.props.currentUser.username
        )
      );
    });
    return (
      <div style={wrapperStyle}>
        <div style={titleStyle}>Task Reference</div>
        <div style={createTaskButtonWrapperStyle}>
          <IconButtonSolid
            name={"Create Task"}
            width={"114px"}
            height={"31px"}
            iconPos={"Left"}
            icon={<IconSVG name={"speaker"} color={"white"} size={"0.6"} />}
            size={"Small"}
            clickEvent={this.props.handleCreateTask}
            disabled={!this.props.editable}
          />
        </div>
        <div
          style={{ maxHeight: "30rem", overflowY: "scroll" }}
          className={"scrollbar"}
        >
          {taskList.map((task, index) => {
            //console.log(task);
            return (
              <div style={taskStyleWrapper}>
                <div style={createTaskButtonWrapperStyle}>
                  <ButtonSolid
                    name={"View Detail"}
                    width={"101px"}
                    height={"31px"}
                    color={"Secondary"}
                    clickEvent={() => {
                      this.props.handleViewTask(task);
                    }}
                  />
                </div>
                {/*task description*/}
                <div style={sectionTitleStyle}>Description</div>
                <div style={contentStyle}>{task.description}</div>
                <div style={{ height: "28px" }} />

                <div style={{ display: "inline-flex" }}>
                  <div style={{ width: "192px", marginRight: "13px" }}>
                    <div style={sectionTitleStyle}>Assigned To</div>
                    <div style={shortContentStyle}>
                      {
                        this.props.userList.filter((user) => {
                          return user.username === task.to[0];
                        })[0].display_name
                      }
                    </div>
                  </div>
                  <div style={{ width: "145px", marginRight: "13px" }}>
                    <div style={sectionTitleStyle}>Due Date</div>
                    <div style={shortContentStyle}>{task.dueDate}</div>
                  </div>
                  <div style={{ width: "145px", marginRight: "13px" }}>
                    <div style={sectionTitleStyle}>Status</div>
                    <div style={shortContentStyle}>{task.status}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const taskStyleWrapper = {
  position: "relative",
  margin: "0 0 20px 20px",
  padding: "16px 0 0 17px",
  height: "209px",
  width: "540px",
  backgroundColor: "#212938",
};
const titleStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "17.7px 0 40px 17px",
};
const wrapperStyle = {
  position: "relative",
  paddingBottom: "20px",
  borderRadius: "10px",
  backgroundColor: "rgba(0,0,0,0.4)",
  height: "fit-content",
  width: "581px",
};
const BlurEffectStyle = {
  display: "inline-flex",
  width: "100%",
  filter: "blur(10px)",
};

const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  marginBottom: "16px",
};

const contentStyle = {
  ...mapping["forms/label/1-default"],
  padding: 0,
  paddingLeft: "13px",
  display: "flex",
  alignItems: "center",
  marginTop: "9px",
  backgroundColor: "rgba(0,0,0,0.15)",
  height: "40px",
  width: "351px",
};

const shortContentStyle = {
  ...mapping["forms/label/1-default"],
  padding: 0,
  paddingLeft: "13px",
  display: "flex",
  alignItems: "center",
  marginTop: "9px",
  marginRight: "40px",
  height: "40px",
  width: "inherit",
  backgroundColor: "rgba(0,0,0,0.15)",
};

const createTaskButtonWrapperStyle = {
  position: "absolute",
  top: "11px",
  right: "18px",
};
export { TaskReference };
