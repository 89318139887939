import React from "react";
import {
  mapping,
  AutoSizeTextArea,
  MultiSelector,
  ButtonSolid,
  IconButtonSolid,
  IconSVG,
} from "@aim-mf/styleguide";

class DeclineTransferPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
    };
  }

  render() {
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Decline an Alert Transfer
            </div>
            <div style={{ height: "50px" }} />
            <div style={mapping["forms/label/1-default"]}>Description</div>
            <AutoSizeTextArea
              fieldstyle={{ ...InputFieldStyle }}
              minRows={3}
              CustomizedOnChange={(value) => {
                // eslint-disable-next-line no-console
                this.setState({ msg: value });
              }}
            />
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Send Transfer Request"}
                color={"Primary"}
                width={"197px"}
                height={"39px"}
                clickEvent={() => {
                  this.props.submitFunction(this.state.msg);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "100%",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "579px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};

export { DeclineTransferPopup };
