import React from "react";
import { AlertBasicInfo } from "./components/alertBasicInfo";
import { AlertDetail } from "./components/alertDetail";
import { TaskReference } from "./components/taskReference";
import { TransferAlertPopup } from "./components/transferAlertPopup";
import { ReportAlertPopup } from "./components/reportAlertPopup";
import {
  ButtonSolid,
  IconButtonSolid,
  IconSVG,
  mapping,
  TaskForm,
  ConfirmationPopup,
  ViewTask,
  Loading,
} from "@aim-mf/styleguide";
import { DeclineTransferPopup } from "./components/declineTransferPopup";
import { navigateToUrl } from "single-spa";

let testingDetailData = {
  vendor_id: "V001",
  contact_person: "James Yap",
  addressL: "@22-22-222 Bedom Street 2 22",
  created_date: "12/02/2018",
  email: "JY@Sauraivan.com.sg",
  country: "Singapore",
  postal: "222221",
  approved_date: "12/11/2018",
  uen: "LK130000098",
  telephone: "88899998",
};

class AlertManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      transfer: false,
      report: false,
      declineTransfer: false,
      Confirmation: { display: false },
      alert: {},
      taskList: this.props.taskList,
      alertDetail: testingDetailData,
      warning: { display: false },
      viewTask: { display: false },
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.alert) !== JSON.stringify(this.props.alert)) {
      //console.log(nextProps.alert);
      this.setState({
        alert: nextProps.alert,
      });
    }
  }

  render() {
    if (Object.keys(this.state.alert).length === 0) {
      return <div />;
    }
    return (
      <div>
        <div
          style={
            this.state.createTask ||
            this.state.transfer ||
            this.state.report ||
            this.state.viewTask.display ||
            this.state.declineTransfer ||
            this.props.loading
              ? BlurEffectStyle
              : { width: "100%", paddingLeft: "90px", paddingBottom: "30px" }
          }
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginTop: "17px",
              marginBottom: "24px",
            }}
          >
            <IconSVG name={"home"} color={mapping["Color_Basic_Primary"]} />
            <div
              style={{
                ...mapping["symbols/breadcrumbs/active"],
                color: "#00A2FF",
              }}
            >
              My task
            </div>
            <div
              style={{
                ...mapping["symbols/breadcrumbs/active"],
                color: "#6C757D",
              }}
            >
              / Alert
            </div>
          </div>
          <div style={{ marginBottom: "43px" }}>
            <div style={{ display: "inline-flex" }}>
              <ButtonSolid
                name={"Back"}
                color={"Secondary"}
                width={"65px"}
                height={"31px"}
                clickEvent={() => {
                  navigateToUrl(location.origin + "/task-management");
                }}
              />
              <div style={{ width: "1314px" }} />
              <IconButtonSolid
                name={"Report this to Risk Manager"}
                size={"Small"}
                color={"Warning"}
                width={"215px"}
                height={"31px"}
                iconPosr={"Left"}
                icon={<IconSVG name={"warning"} size={"0.6"} />}
                clickEvent={
                  this.state.createTask || this.state.transfer
                    ? {}
                    : this.handleReportAlert
                }
                disabled={
                  !(
                    this.props.currentUser.username ===
                    this.props.alert.owners[0]
                  ) || this.props.alert.status === "closed"
                }
              />
              <div style={{ width: "20px" }} />
              <IconButtonSolid
                name={"Transfer Alert"}
                size={"Small"}
                color={"Danger"}
                width={"125px"}
                height={"31px"}
                iconPosr={"Left"}
                icon={<IconSVG name={"people"} size={"0.6"} />}
                clickEvent={
                  this.state.createTask || this.state.report
                    ? {}
                    : this.handleTransferAlert
                }
                disabled={
                  !(
                    this.props.currentUser.username ===
                    this.props.alert.owners[0]
                  ) || this.props.alert.status === "closed"
                }
              />
            </div>
          </div>
          <div style={{ display: "inline-flex" }}>
            <div style={{ marginRight: "60px" }}>
              <AlertBasicInfo
                alert={this.state.alert}
                uploadingFile={this.state.alert.actions.map(
                  (action) => action.attachment_name
                )}
                currentUser={this.props.currentUser}
                userList={this.props.userList}
                risk={this.props.risk}
                parentRisk={this.props.parentRisk}
                handleAddAction={this.handleAddAction}
                handleDeleteAction={this.handleDeleteAction}
                handleSelectAction={this.handleSelectAction}
                onChangeTextField={this.onChangeTextField}
                handleRationalChange={this.handleRationalChange}
                handleAlertComplete={this.handleAlertComplete}
                handleSaveChanges={this.handleSaveChanges}
                handleUsefulChange={this.handleUsefulChange}
                handleAttachmentChange={this.handleAttachmentChange}
                handleRemoveUploadFile={this.handleRemoveUploadFile}
                handleAttachmentDownload={this.props.handleAttachmentDownload}
                handleAcceptTransferAlert={this.props.handleAcceptTransferAlert}
                handleConfirmation={this.handleConfirmation}
                handleDeclineTransferPopup={this.handleDeclineTransferPopup}
                editable={
                  this.props.currentUser.username ===
                    this.props.alert.owners[0] &&
                  this.props.alert.status === "open"
                }
              />
              <div style={{ height: "40px" }} />
              <TaskReference
                task={this.props.taskList}
                userList={this.props.userList}
                currentUser={this.props.currentUser}
                handleCreateTask={
                  this.state.report || this.state.transfer
                    ? {}
                    : this.handleCreateTask
                }
                handleViewTask={
                  this.state.report || this.state.transfer
                    ? {}
                    : (content) => {
                        this.handleViewTask(content);
                      }
                }
                editable={
                  this.props.currentUser.username ===
                    this.props.alert.owners[0] &&
                  this.props.alert.status === "open"
                }
              />
            </div>
            <AlertDetail alertDetail={this.state.alertDetail} />
          </div>
        </div>
        {this.state.createTask && (
          <TaskForm
            currentUser={this.props.currentUser.username}
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ createTask: false });
            }}
            submitFunction={(taskContent) => {
              this.props.handleCreateTask(taskContent);
              this.setState({ createTask: false });
            }}
          />
        )}
        {this.state.viewTask.display && (
          <ViewTask
            taskCardData={this.state.viewTask.content}
            currentUser={this.props.currentUser.username}
            cancelFunction={() => {
              //console.log("close function");
              this.setState({ viewTask: { display: false } });
            }}
            submitFunction={(taskContent) => {
              this.props.handleCreateTask(taskContent);
              this.setState({ createTask: false });
            }}
          />
        )}
        {this.state.transfer && (
          <TransferAlertPopup
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ transfer: false });
            }}
            submitFunction={this.handleSubmitTransferAlert}
          />
        )}
        {this.state.report && (
          <ReportAlertPopup
            riskManager={this.props.userList.filter((user) => {
              //console.log(user.title)
              //console.log(this.props.reportTargetTitle)
              return user.title === this.props.reportTargetTitle;
            })}
            cancelFunction={() => {
              this.setState({ report: false });
            }}
            submitFunction={this.handleSubmitReportAlert}
          />
        )}
        {this.state.declineTransfer && (
          <DeclineTransferPopup
            cancelFunction={() => {
              this.setState({ declineTransfer: false });
            }}
            submitFunction={(msg) => {
              this.props.handleDeclineTransferAlert(
                this.props.alert.past_owners[
                  this.props.alert.past_owners.length - 1
                ],
                msg
              );
              this.setState({ declineTransfer: false });
            }}
          />
        )}
        {this.state.warning.display && (
          <ConfirmationPopup
            title={this.state.warning.title}
            content={this.state.warning.content}
            cancelFunction={() => {
              this.setState({ warning: { display: false } });
            }}
            confirmFunction={() => {
              this.state.warning.confirmFunction();
              this.setState({ warning: { display: false } });
            }}
          />
        )}

        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
      </div>
    );
  }
  handleAttachmentChange = (index, e) => {
    //console.log(index, this.state.alert.actions[index]);
    let uploadFile = this.state.alert.actions[index].newAttachments;
    let uploadingFile = uploadFile ? uploadFile : [];
    let file;
    if (e.target) {
      file = e.target.files[0];
      if (file) {
        uploadingFile.push({
          attachment_file: file,
          name: file.name,
          content_type: file.type,
        });
      }
    } else {
      if (e[0] !== undefined) {
        //console.log(e[0]);
        file = e[0];
        uploadingFile.push({
          attachment_file: file,
          name: file.name,
          content_type: file.type,
        });
      }
    }

    let alert = this.state.alert;
    alert.actions[index].newAttachments = uploadingFile;
    alert.actions[index].attachments.push(file);
    this.setState({ alert: alert });
  };

  handleConfirmation = (title, content, confirmFunction) => {
    this.setState({
      warning: {
        display: true,
        title: title,
        content: content,
        confirmFunction: confirmFunction,
      },
    });
  };

  handleDeclineTransferPopup = () => {
    this.setState({ declineTransfer: true });
  };

  handleRemoveUploadFile = (actionIndex, index) => {
    let alert = this.state.alert;
    let action = alert.actions[actionIndex];
    let attachments = action.attachments;
    let newAttachments = action.newAttachments;
    let offset = attachments.length - newAttachments.length;
    let removeAttachmentID = action.removeAttachmentID;
    removeAttachmentID = removeAttachmentID ? removeAttachmentID : [];
    if (attachments[index].id) {
      removeAttachmentID.push(attachments[index].id);
    }
    newAttachments.splice(index - offset, 1);
    attachments.splice(index, 1);

    action.removeAttachmentID = removeAttachmentID;
    this.setState({ alert: alert });
  };

  handleSubmitTransferAlert = (username, transferComment) => {
    //console.log(username);
    this.props.handleTransferAlert(username, transferComment);
    this.setState({ transfer: false });
  };
  handleSubmitReportAlert = (username, reportComment) => {
    //console.log(username);
    this.props.handleReportAlert(username, reportComment);
    this.setState({ report: false });
  };
  handleUsefulChange = (value) => {
    let alert = this.state.alert;
    alert.is_useful = value;
    this.setState({ alert: alert });
  };

  handleSaveChanges = () => {
    let actions = [];
    this.state.alert.actions.map((action, index) => {
      //console.log(action);
      actions.push({
        new_attachments: action.newAttachments,
        comment_type: action.comment_type,
        comment: action.comment,
        attachments: action.attachments,
        id: action.id,
        removeAttachmentID: action.removeAttachmentID,
      });
    });
    let alert = this.state.alert;
    let dataPackage = {
      rational: alert.rational,
      description: alert.description,
      is_useful: alert.is_useful === "Yes",
      due_date: alert.due_date,
      owners: alert.owners,
      risk_id: alert.risk_id,
      actions: actions,
    };
    //console.log(dataPackage);
    this.props.handleUpdateAlert({ data: dataPackage });
  };

  handleAddAction = () => {
    let alert = this.state.alert;
    alert.actions.push({
      comment_type: "Select Form",
      comment: "",
      is_new: true,
      attachments: [],
    });
    this.setState({ alert: alert });
  };
  handleDeleteAction = (index) => {
    let alert = this.state.alert;
    alert.actions.splice(index, 1);
    this.setState({ alert: alert });
  };
  handleSelectAction = (index, value) => {
    if (value !== undefined) {
      let alert = this.state.alert;
      alert.actions[index].comment_type = value;
      this.setState({ alert: alert });
    }
  };
  onChangeTextField = (index, value) => {
    let alert = this.state.alert;
    alert.actions[index].comment = value;
    this.setState({ alert: alert });
  };

  handleCreateTask = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ createTask: true, transfer: false, report: false });
  };
  handleTransferAlert = () => {
    this.setState({ transfer: true, report: false, createTask: false });
  };
  handleReportAlert = () => {
    this.setState({ report: true, createTask: false, transfer: false });
  };
  handleViewTask = (content) => {
    //console.log(content);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      report: false,
      createTask: false,
      transfer: false,
      viewTask: { display: true, content: content },
    });
  };
  handleRationalChange = (value) => {
    let alert = this.state.alert;
    alert.rational = value;
    this.setState({ alert: alert });
  };
  handleAlertComplete = () => {
    if (this.state.alert.rational === "") {
      this.setState({
        warning: {
          display: true,
          title: "Mark as completed failed",
          content: "Rational is required to mark this alert as completed",
          confirmFunction: () => {
            this.setState({
              warning: {
                display: false,
              },
            });
          },
        },
      });
    } else {
      this.setState({
        warning: {
          display: true,
          title: "Mark as completed",
          content:
            "Marking this alert as complete will close this alert, are you sure you want to close this alert?",
          confirmFunction: () => {
            this.props.handleCompleteAlert();
          },
        },
      });
    }
  };
}

const BlurEffectStyle = {
  paddingLeft: "90px",
  paddingBottom: "30px",

  width: "100%",
  filter: "blur(10px)",
};

export { AlertManagement };
