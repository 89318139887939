import React from "react";
import { mapping } from "@aim-mf/styleguide";

class AlertDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    return (
      <div style={wrapperStyle}>
        <div style={titleStyle}>Alert Detail</div>

        <div style={detailStyleWrapper}>
          <div>
            <div style={sectionTitleStyle}>SAMURAI VAN</div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "30px" }}>
            <div style={{ width: "150px", marginRight: "89px" }}>
              <div style={mapping["forms/label/1-default"]}>Vendor ID</div>
              <div style={contentStyle}>{this.props.alertDetail.vendor_id}</div>
            </div>
            <div style={{ width: "360px", marginRight: "40px" }}>
              <div style={mapping["forms/label/1-default"]}>Contact Person</div>
              <div style={contentStyle}>
                {this.props.alertDetail.contact_person}
              </div>
            </div>
            <div style={{ width: "360px" }}>
              <div style={mapping["forms/label/1-default"]}>Address</div>
              <div style={contentStyle}>{this.props.alertDetail.addressL}</div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "30px" }}>
            <div style={{ width: "150px", marginRight: "89px" }}>
              <div style={mapping["forms/label/1-default"]}>Created Date</div>
              <div style={contentStyle}>
                {this.props.alertDetail.created_date}
              </div>
            </div>
            <div style={{ width: "360px", marginRight: "40px" }}>
              <div style={mapping["forms/label/1-default"]}>Email</div>
              <div style={contentStyle}>{this.props.alertDetail.email}</div>
            </div>
            <div style={{ width: "150px", marginRight: "60px" }}>
              <div style={mapping["forms/label/1-default"]}>Country</div>
              <div style={contentStyle}>{this.props.alertDetail.country}</div>
            </div>
            <div style={{ width: "150px" }}>
              <div style={mapping["forms/label/1-default"]}>Postal</div>
              <div style={contentStyle}>{this.props.alertDetail.postal}</div>
            </div>
          </div>

          {/*line three*/}
          <div style={{ display: "inline-flex", paddingBottom: "30px" }}>
            <div style={{ width: "150px", marginRight: "89px" }}>
              <div style={mapping["forms/label/1-default"]}>ApprovedDate</div>
              <div style={contentStyle}>
                {this.props.alertDetail.approved_date}
              </div>
            </div>
            <div style={{ width: "360px", marginRight: "40px" }}>
              <div style={mapping["forms/label/1-default"]}>UEN</div>
              <div style={contentStyle}>{this.props.alertDetail.uen}</div>
            </div>
            <div style={{ width: "360px" }}>
              <div style={mapping["forms/label/1-default"]}>Telephone</div>
              <div style={contentStyle}>{this.props.alertDetail.telephone}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const titleStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "17.7px 0 40px 17px",
};
const wrapperStyle = {
  height: "fit-content",
  width: "1100px",
  borderRadius: "10px",
  backgroundColor: "rgba(0,0,0,0.4)",
};
const sectionTitleStyle = {
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "#00BEFF",
  marginBottom: "16px",
};

const contentStyle = {
  ...mapping["forms/label/1-default"],
  padding: 0,
  paddingLeft: "13px",
  display: "flex",
  alignItems: "center",
  marginTop: "9px",
  height: "40px",
  width: "inherit",
  backgroundColor: "rgba(0,0,0,0.15)",
};

const detailStyleWrapper = {
  position: "relative",
  margin: "0 0 20px 20px",
  padding: "20px 0 20px 27px",
  width: "1054px",
  backgroundColor: "#212938",
};
const BlurEffectStyle = {
  display: "inline-flex",
  width: "100%",
  filter: "blur(10px)",
};

export { AlertDetail };
