import React, { useCallback } from "react";
import {
  fetchTaskAPIWithPermission,
  fetchAlertAPIWithPermission,
  fetchAdminAPIWithPermission,
} from "@aim-mf/api";
import "./app.scss";
import { AlertManagement } from "./page/alertManagement";
import { navigateToUrl } from "single-spa";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const initialState = {
  loadingManager: true,
  loading: false,
  loadingUser: true,
  loadingAlert: true,
  loadingTask: true,
  taskList: [],
  userList: [],
  alert: {},
  parentRisk: {},
  risk: {},
  currentUser: {},
};

function App(props) {
  let AlertID = document.location.pathname.split("/");
  AlertID = parseInt(AlertID[AlertID.length - 2]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const {
    loadingManager,
    loading,
    loadingUser,
    userList,
    loadingAlert,
    loadingTask,
    taskList,
    alert,
    risk,
    parentRisk,
    currentUser,
  } = state;

  React.useEffect(() => {
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadUserFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingAlert) {
      dispatch({ type: "loadingAlert" });
      const userSubscription = fetchAlertAPIWithPermission(
        "getalert/" + AlertID
      ).subscribe(
        (results) => {
          dispatch({ type: "loadAlert", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadAlertFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingTask) {
      dispatch({ type: "loadingTask" });
      const taskSubscription = fetchTaskAPIWithPermission(
        "gettasks/" + AlertID
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          dispatch({ type: "loadTask", results });
        },
        (err) => {
                  console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadTaskFunction();
          });
        }
      );
      return () => taskSubscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingAlert,
    loadingTask,
    AlertID,
    loadUserFunction,
    loadAlertFunction,
    loadTaskFunction,
  ]);

  const loadUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadUserFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });

  const loadAlertFunction = useCallback(() => {
    const userSubscription = fetchAlertAPIWithPermission(
      "getalert/" + AlertID
    ).subscribe(
      (results) => {
        dispatch({ type: "loadAlert", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadAlertFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });

  const loadTaskFunction = useCallback(() => {
    const taskSubscription = fetchTaskAPIWithPermission(
      "gettasks/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        dispatch({ type: "loadTask", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadTaskFunction();
        });
      }
    );
    return () => taskSubscription.unsubscribe();
  });

  const handleCompleteAlert = React.useCallback(() => {
    //console.log(transferee);
    const userSubscription = fetchAlertAPIWithPermission(
      "closealert/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleCreateTask = React.useCallback((taskinfo) => {
    taskinfo.alert_id = AlertID;
    const userSubscription = fetchTaskAPIWithPermission("tasks/", {
      data: taskinfo,
    }).subscribe(
      (results) => {
        // once created, need to reload tasks
        dispatch({ type: "loadingTask" });
        //console.log(taskinfo)
        handleAddAlertViwer(taskinfo.to, AlertID);
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleAddAlertViwer = React.useCallback((username, alert_id) => {
    const userSubscription = fetchAlertAPIWithPermission(
      "addalertviewer/" + alert_id,
      { data: username }
    ).subscribe(
      (results) => {
        // once created, need to reload tasks
        //dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleUpdateAlert = React.useCallback((newalert) => {
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "updatealert/" + AlertID,
      newalert
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        //console.log("---------------------------------");
        //console.log(results.data);
        //console.log(newalert.data);
        let reload = true;
        results.data.actions.map((action, index) => {
          if (action.comment_type === "Upload Request") {
            newalert.data.actions.forEach((actionattachment) => {
              //console.log(actionattachment);

              // when the action is already exist
              if (
                actionattachment.id !== undefined &&
                actionattachment.new_attachments !== undefined
              ) {
                reload = false;
                handleUploadAlertAttachment(
                  actionattachment.new_attachments.map(
                    (a) => a.attachment_file
                  ),
                  actionattachment.id
                );
              }

              // create new action, and upload file at same time.
              if (
                actionattachment.id === undefined &&
                actionattachment.new_attachments !== undefined &&
                actionattachment.comment_type === "Upload Request" &&
                actionattachment.comment === action.comment
              ) {
                reload = false;
                handleUploadAlertAttachment(
                  actionattachment.new_attachments.map(
                    (a) => a.attachment_file
                  ),
                  action.id
                );
              }
            });
          }
        });
        newalert.data.actions.forEach((action) => {
          if (
            action.comment_type === "Upload Request" &&
            action.removeAttachmentID !== undefined
          ) {
            reload = false;
            handleRemoveAlertAttachment(action.removeAttachmentID);
          }
        });
        if (reload) {
          dispatch({ type: "loadingAlert" });
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleRemoveAlertAttachment = React.useCallback((idList) => {
    const userSubscription = fetchAlertAPIWithPermission("removeattachment/", {
      data: idList,
    }).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("delete success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });
  const handleUploadAlertAttachment = React.useCallback((files, id) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file);
    });
    const userSubscription = fetchAlertAPIWithPermission(
      "uploadattachment/alertaction/" + id,
      { data: data }
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("upload success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
              console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const extractContentType = (response) => {
    //console.log(response);
    return response.headers["content-type"];
  };

  const handleAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchAlertAPIWithPermission(
        "alertaction/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
              console.log("err", err); // eslint-disable-line
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );

  const handleReportAlert = React.useCallback(
    (riskManager, transferComment) => {
      //console.log(riskManager)
      dispatch({ type: "loading" });
      //console.log(transferComment);
      const userSubscription = fetchAlertAPIWithPermission(
        "reportalert/" + AlertID,
        { data: { username: riskManager[0], comment: transferComment } }
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          console.log("report success");
          dispatch({ type: "loadingAlert" });
        },
        (err) => {
              console.log("err", err); // eslint-disable-line
        }
      );
      return () => userSubscription.unsubscribe();
    }
  );

  const handleTransferAlert = React.useCallback(
    (transferee, transferComment) => {
      dispatch({ type: "loading" });
      //console.log(transferComment);
      const userSubscription = fetchAlertAPIWithPermission(
        "transferalert/" + AlertID,
        { data: { username: transferee[0], comment: transferComment } }
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          console.log("transfered success");
          dispatch({ type: "loadingAlert" });
        },
        (err) => {
          console.log("err", err); // eslint-disable-line
        }
      );
      return () => userSubscription.unsubscribe();
    }
  );

  const handleAcceptTransferAlert = React.useCallback(() => {
    //console.log(transferComment);
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "acceptalert/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("accept transferred success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });
  const handleDeclineTransferAlert = React.useCallback((username, msg) => {
    //console.log(msg);
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "rejectalert/" + AlertID,
      { data: { username: username, comment: msg } }
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("reject transferred success");
        navigateToUrl("/task-management");
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });
  return (
    <div>
      <AlertManagement
        reportTargetTitle={"Risk Manager"}
        loading={loading}
        handleTransferAlert={handleTransferAlert}
        currentUser={currentUser}
        handleUpdateAlert={handleUpdateAlert}
        userList={userList}
        taskList={taskList}
        alert={alert}
        risk={risk}
        parentRisk={parentRisk}
        handleCompleteAlert={handleCompleteAlert}
        handleCreateTask={handleCreateTask}
        handleAttachmentDownload={handleAttachmentDownload}
        handleAcceptTransferAlert={handleAcceptTransferAlert}
        handleDeclineTransferAlert={handleDeclineTransferAlert}
        handleReportAlert={handleReportAlert}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loading":
        //console.log("start loading");
        return {
          ...state,
          loading: true,
        };
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "loadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: true };
      case "loadingAlert":
        // console.log("in loading riskList")
        return { ...state, loadingAlert: true };
      case "loadingTask":
        // console.log("in loading riskList")
        return { ...state, loadingTask: true };

      case "loadUser":
        return {
          ...state,
          userList: action.results.data,
          loadingUser: false,
          loading: false,
        };
      case "loadTask":
        return {
          ...state,
          taskList: action.results.data,
          loadingTask: false,
          loading: false,
        };
      case "loadAlert":
        //console.log("load alert");
        return {
          ...state,
          alert: action.results.data,
          loadingAlert: false,
          loading: false,
        };
      case "loadRisk":
        return {
          ...state,
          risk: action.results.data,
          loading: false,
        };
      case "loadParentRisk":
        return {
          ...state,
          parentRisk: action.results.data,
          loading: false,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
