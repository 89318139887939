import React from "react";
import {
  mapping,
  NameCard,
  AutoSizeTextArea,
  RadioButtonGroup,
  IconButtonGhost,
  IconSVG,
  ActionDefaultTemplate,
  ActionTextInput,
  ActionUploadRequest,
  ButtonSolid,
  CustomizedCheckBox,
} from "@aim-mf/styleguide";

class AlertBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    //console.log(this.props.alert);
    let status = "";
    let dueDays = Math.ceil(
      (new Date(this.props.alert.due_date) - new Date()) / (1000 * 60 * 60 * 24)
    );

    if (dueDays > 0) {
      status = "Due in " + dueDays + " days";
    } else if (dueDays === 0) {
      status = "Due Today";
    } else {
      status = "Overdue" + -dueDays + " days";
    }

    if (this.props.alert.status === "closed") {
      status = "Closed";
    }

    let TR1 = false, //transfer request (alert owner side)
      TR2 = false, // transfer received {recipient side}
      TA = false, // transfer accepted
      TD = false, // transfer declined
      RR = false, // report request
      RR1 = false, // report request (alert owner side)
      alert = this.props.alert,
      me = this.props.currentUser.username;

    let transferMessage = alert.transfer_messages.filter((msg) => {
      return msg.msg_type === "transfer";
    });

    let alertMessage = alert.transfer_messages.filter((msg) => {
      return msg.msg_type === "report";
    });

    if (alert.owners.includes(me)) {
      if (
        alertMessage.length !== 0 &&
        alertMessage[alertMessage.length - 1].to_user !== me
      ) {
        RR1 = true;
      }
      if (alert.creator !== "SYSTEM") {
        // some body report the alert to manager, so the creator is not system
        RR = true;
      } else if (alert.transferees.length !== 0) {
        // if transferees is not empty, meaning the alert is transfer request stage
        TR1 = true;
      } else if (
        transferMessage[transferMessage.length - 1].from_user !==
        alert.past_owners[alert.past_owners.length - 1]
      ) {
        // if the last transfer message is not from the last past owner, it means the transfer request is declined
        TD = true;
      }
    } else if (alert.transferees.includes(me)) {
      // if i am in the transfer list, somebody has request to transfer to me
      TR2 = true;
    } else if (alert.past_owners.includes(me)) {
      if (alert.transferees.length !== 0) {
        // if i am the past owner, and the transfer list is not empty, it means the alert is in transfer state
        TR1 = true;
      } else {
        // if i am the past owner, and transfer list is empty, he/she has accepted the alert
        TA = true;
      }
    }

    //console.log(this.props.alert);
    if (Object.keys(this.props.alert) === 0) {
      return <div />;
    }
    // console.log(alert);
    return (
      <div style={wrapperStyle}>
        <div
          style={{
            color: "white",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <div style={{ ...titleStyle, color: mapping["Color_Basic_Success"] }}>
            {this.props.alert.status === "closed" ? "Complete" : ""}
          </div>
        </div>
        <div style={titleStyle}>Alert ID: {this.props.alert.alert_number}</div>
        {RR1 && (
          <ReportAlertRequestAlertOwnerSide
            userList={this.props.userList}
            alert={this.props.alert}
            msgList={alertMessage}
          />
        )}
        {TR2 && (
          <ReceiveTransferRequest
            userList={this.props.userList}
            alert={this.props.alert}
            message={transferMessage[transferMessage.length - 1]}
            currentUser={this.props.currentUser}
            handleAcceptTransferAlert={this.props.handleAcceptTransferAlert}
            handleDeclineTransferAlert={this.props.handleDeclineTransferAlert}
            handleConfirmation={this.props.handleConfirmation}
            handleDeclineTransferPopup={this.props.handleDeclineTransferPopup}
          />
        )}
        {TR1 && (
          <TransferRequested
            message={transferMessage[transferMessage.length - 1]}
            currentUser={this.props.currentUser}
            userList={this.props.userList}
            alert={this.props.alert}
          />
        )}
        {TD && (
          <TransferRequestRejected
            message={transferMessage[transferMessage.length - 1]}
            currentUser={this.props.currentUser}
            userList={this.props.userList}
            alert={this.props.alert}
          />
        )}
        {TA && (
          <TransferRequestAccepted
            userList={this.props.userList}
            alert={this.props.alert}
          />
        )}
        {RR && (
          <ReportAlertRequest
            userList={this.props.userList}
            alert={this.props.alert}
          />
        )}
        <OneItemPerLine
          data={{ title: "Description", content: this.props.alert.description }}
        />
        {this.props.alert.parent_risk ? (
          <TwoItemPerLine
            data={[
              { title: "Parent Risk", content: this.props.alert.parent_risk },
              { title: "Sub Risk", content: this.props.alert.risk },
            ]}
          />
        ) : (
          <OneItemPerLine
            data={{ title: "Risk", content: this.props.alert.risk }}
          />
        )}

        <TwoItemPerLine
          data={[
            { title: "Status", content: status },
            { title: "Due Date", content: this.props.alert.due_date },
          ]}
        />
        <RiskOwner
          risk_owner={this.props.alert.owners}
          userList={this.props.userList}
        />
        <Rational
          description={this.props.alert.rational}
          handleChange={this.props.handleRationalChange}
          editable={this.props.editable}
        />
        <Useful
          is_useful={this.props.alert.is_useful}
          handleUsefulChange={this.props.handleUsefulChange}
          editable={this.props.editable}
        />
        <Action
          actions={this.props.alert.actions}
          handleDeleteAction={this.props.handleDeleteAction}
          handleSelectAction={this.props.handleSelectAction}
          onChangeTextField={this.props.onChangeTextField}
          handleRadioSelection={this.props.handleRadioSelection}
          onChangeOptionInput={this.props.onChangeOptionInput}
          handleAddOption={this.props.handleAddOption}
          handleDeleteOption={this.props.handleDeleteOption}
          handleAttachmentChange={this.props.handleAttachmentChange}
          handleRemoveUploadFile={this.props.handleRemoveUploadFile}
          handleAttachmentDownload={this.props.handleAttachmentDownload}
          editable={this.props.editable}
        />
        <div style={centerStyle}>
          <IconButtonGhost
            name={"Add Action"}
            fontStyle={{ color: "white" }}
            height={"2.5rem"}
            width={"8rem"}
            color={"Primary"}
            iconPos={"Right"}
            icon={<IconSVG name={"newRisk"} color={"white"} />}
            clickEvent={this.props.handleAddAction}
            disabled={!this.props.editable}
          />
        </div>
        <div style={{ height: "39px" }} />
        <div style={twoButtonsCenter}>
          <ButtonSolid
            name={"Save Changes"}
            height={"38px"}
            width={"161px"}
            clickEvent={this.props.handleSaveChanges}
            disabled={!this.props.editable}
          />

          <ButtonSolid
            name={"Mark as complete"}
            fontStyle={{ color: "white" }}
            height={"38px"}
            width={"161px"}
            color={"Secondary"}
            clickEvent={this.props.handleAlertComplete}
            disabled={!this.props.editable}
          />
        </div>
        <div style={{ height: "39px" }} />
      </div>
    );
  }
}
const RiskOwner = (props) => {
  return (
    <div>
      <div style={mapping["forms/label/1-default"]}>Alert Owner</div>
      {props.risk_owner.map((username, index) => {
        let owner = props.userList.filter((user) => {
          return user.username === username;
        })[0];
        if (Object.keys(owner).length === 0) {
          return <div />;
        } else {
          return (
            <NameCard
              key={owner.display_name + owner.title}
              initial={owner.display_name
                .split(" ")
                .map((a) => {
                  return a[0].toUpperCase();
                })
                .join("")}
              name={owner.display_name}
              title={owner.title}
            />
          );
        }
      })}

      <div style={{ height: "28px" }} />
    </div>
  );
};
const Rational = (props) => {
  const handleChange = (value) => {
    props.handleChange(value);
  };
  return (
    <div>
      <div style={mapping["forms/label/1-default"]}>Rational</div>
      <TextInput
        minRows={3}
        width={"540px"}
        value={props.description}
        onCustomChange={handleChange}
        disabled={!props.editable}
      />
      <div style={{ height: "28px" }} />
    </div>
  );
};
const Useful = (props) => {
  const radioGroupData = [
    { label: "Yes", value: "Yes", className: "task blue" },
    { label: "No", value: "No", className: "task blue" },
  ];
  return (
    <div>
      <div style={mapping["forms/label/1-default"]}>Is this useful?</div>
      <RadioButtonGroup
        data={radioGroupData}
        defaultValue={props.is_useful ? "Yes" : "No"}
        layout={"vertical"}
        CustomOnChange={(event) => {
          // eslint-disable-next-line no-console
          props.handleUsefulChange(event.value);
        }}
        disabled={!props.editable}
      />
      <div style={{ height: "28px" }} />
    </div>
  );
};
const Action = (props) => {
  //console.log(props.actions);
  return (
    <div
      style={{ marginRight: "23px", maxHeight: "30rem", overflowY: "scroll" }}
      className={"scrollbar"}
    >
      {props.actions.map((action, index) => {
        let actionsContent = action.comment;
        if (action.comment_type === "Select Form") {
          return (
            <ActionDefaultTemplate
              index={index}
              action={action.comment_type}
              data={["Text Field", "Upload Request"]}
              selectedAction={action.comment_type}
              handleDeleteAction={props.handleDeleteAction}
              handleSelectAction={props.handleSelectAction}
            />
          );
        } else if (action.comment_type === "Text Field") {
          return (
            <ActionTextInput
              index={index}
              action={action.comment_type}
              actionsContent={{ description: actionsContent }}
              data={["Text Field", "Upload Request"]}
              handleDeleteAction={props.handleDeleteAction}
              handleSelectAction={props.handleSelectAction}
              onChangeTextField={props.onChangeTextField}
              disabled={!props.editable}
            />
          );
        } else if (action.comment_type === "Upload Request") {
          return (
            <ActionUploadRequest
              index={index}
              action={action.comment_type}
              actionsContent={{ description: actionsContent }}
              data={["Text Field", "Upload Request"]}
              uploadingFile={action.attachments}
              handleDeleteAction={props.handleDeleteAction}
              handleSelectAction={props.handleSelectAction}
              onChangeUploadRequest={props.onChangeTextField}
              handleAttachmentChange={(e) => {
                props.handleAttachmentChange(index, e);
              }}
              handleRemoveUploadFile={(e) => {
                props.handleRemoveUploadFile(index, e);
              }}
              handleAttachmentDownload={props.handleAttachmentDownload}
              disabled={!props.editable}
            />
          );
        }
      })}
    </div>
  );
};
const OneItemPerLine = (props) => {
  return (
    <div>
      <div style={mapping["forms/label/1-default"]}>{props.data.title}</div>
      <div style={contentStyle}>{props.data.content}</div>
      <div style={{ height: "28px" }} />
    </div>
  );
};

const TwoItemPerLine = (props) => {
  return (
    <div style={{ display: "inline-flex" }}>
      {props.data.map((data) => {
        return (
          <div>
            <div style={mapping["forms/label/1-default"]}>{data.title}</div>
            <div style={shortContentStyle}>{data.content}</div>
            <div style={{ height: "28px" }} />
          </div>
        );
      })}
    </div>
  );
};
const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value);
  };
  return (
    <AutoSizeTextArea
      minRows={props.minRows}
      fieldstyle={{
        ...InputFieldStyle,
        width: props.width,
        height: props.height,
      }}
      value={props.value}
      CustomizedOnChange={onChange}
      disabled={props.disabled}
    />
  );
};

const TransferRequestRejected = (props) => {
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#281b25",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Danger"],
          }}
        />
        <div>
          <div
            style={{
              ...transferMessageTitleStyle,
              color: mapping["Color_Basic_Danger"],
            }}
          >
            {
              props.userList.filter((user) => {
                return user.username === props.message.from_user;
              })[0].display_name
            }{" "}
            rejected your alert transfer request
          </div>
          {props.message.to_user === props.currentUser.username && (
            <div
              style={{
                ...mapping["forms/label/1-default"],
                margin: "0 0 7px 30px",
              }}
            >
              "{props.message.comment}"
            </div>
          )}
        </div>
      </div>
      <div style={{ height: "15px" }} />
    </div>
  );
};
const TransferRequestAccepted = (props) => {
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#41825d32",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Success"],
          }}
        />
        <div
          style={{
            ...transferMessageTitleStyle,
            color: mapping["Color_Basic_Success"],
          }}
        >
          {
            props.userList.filter((user) => {
              return user.username === props.alert.owners[0];
            })[0].display_name
          }{" "}
          has accepted the alert transfer request
        </div>
      </div>
      <div style={{ height: "15px" }} />
    </div>
  );
};
const TransferRequested = (props) => {
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#112637",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Primary"],
          }}
        />
        <div>
          <div style={transferMessageTitleStyle}>
            Transfer request sent to{" "}
            {
              props.userList.filter((user) => {
                return user.username === props.alert.transferees[0];
              })[0].display_name
            }
          </div>
          {props.message.from_user === props.currentUser.username && (
            <div
              style={{
                ...mapping["forms/label/1-default"],
                margin: "0 0 7px 30px",
              }}
            >
              "{props.message.comment}"
            </div>
          )}
        </div>
      </div>
      <div style={{ height: "15px" }} />
    </div>
  );
};
const ReportAlertRequestAlertOwnerSide = (props) => {
  let report_msg = props.msgList[props.msgList.length - 1];
  let To_username = report_msg.to_user;
  let To_user_display_name = props.userList.filter((user) => {
    return user.username === To_username;
  })[0].display_name;
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#ffc1071B",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Warning"],
          }}
        />
        <div>
          <div
            style={{
              ...transferMessageTitleStyle,
              color: mapping["Color_Basic_Warning"],
            }}
          >
            this risk has been reported to {To_user_display_name}
          </div>
          <div
            style={{
              ...mapping["forms/label/1-default"],
              margin: "0 0 7px 30px",
            }}
          >
            "{report_msg.comment}"
          </div>
        </div>
      </div>
      <div style={{ height: "15px" }} />
    </div>
  );
};
const ReportAlertRequest = (props) => {
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#ffc1071B",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Warning"],
          }}
        />
        <div>
          <div
            style={{
              ...transferMessageTitleStyle,
              color: mapping["Color_Basic_Warning"],
            }}
          >
            {
              props.userList.filter((user) => {
                return user.username === props.alert.creator;
              })[0].display_name
            }{" "}
            reported this alert to you
          </div>
          <div
            style={{
              ...mapping["forms/label/1-default"],
              margin: "0 0 7px 30px",
            }}
          >
            "{props.alert.transfer_messages[0].comment}"
          </div>
        </div>
      </div>
      <div style={{ height: "15px" }} />
    </div>
  );
};
const ReceiveTransferRequest = (props) => {
  return (
    <div style={{ width: contentStyle.width }}>
      <div
        style={{
          height: "81px",
          width: "100%",
          display: "inline-flex",
          backgroundColor: "#112637",
        }}
      >
        <div
          style={{
            width: "4.4px",
            height: "81px",
            backgroundColor: mapping["Color_Basic_Primary"],
          }}
        />
        <div>
          <div style={transferMessageTitleStyle}>
            {
              props.userList.filter((user) => {
                return (
                  user.username ===
                  props.alert.past_owners[props.alert.past_owners.length - 1]
                );
              })[0].display_name
            }{" "}
            has transferred the alert to you
          </div>
          {props.message.to_user === props.currentUser.username && (
            <div
              style={{
                ...mapping["forms/label/1-default"],
                margin: "0 0 7px 30px",
              }}
            >
              "{props.message.comment}"
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ButtonSolid
          name={"Decline"}
          color={"Danger"}
          clickEvent={props.handleDeclineTransferPopup}
        />
        <div style={{ width: "10px" }} />
        <ButtonSolid
          name={"Accept"}
          color={"Success"}
          clickEvent={() => {
            props.handleConfirmation(
              "Accept Alert Transfer",
              "You are about to accept this alert transfer, you will become the owner of this alert",
              props.handleAcceptTransferAlert
            );
          }}
        />
      </div>
    </div>
  );
};

const shortContentStyle = {
  ...mapping["forms/label/1-default"],
  padding: 0,
  paddingLeft: "13px",
  display: "flex",
  alignItems: "center",
  marginTop: "9px",
  marginRight: "40px",
  height: "40px",
  width: "250px",
  backgroundColor: "rgba(0,0,0,0.15)",
};

const contentStyle = {
  ...mapping["forms/label/1-default"],
  padding: 0,
  paddingLeft: "13px",
  display: "flex",
  alignItems: "center",
  marginTop: "9px",
  height: "40px",
  width: "540px",
  backgroundColor: "rgba(0,0,0,0.15)",
};

const titleStyle = {
  ...mapping["heading/h5/lightleft"],
  padding: "0 0 45px 0",
};

const wrapperStyle = {
  position: "relative",
  padding: "17.7px 0 0 17px",
  height: "fit-content",
  width: "580px",
  borderRadius: "10px",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "63rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const centerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "1rem",
};
const twoButtonsCenter = {
  width: "100%",
  paddingRight: "20px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "1rem",
};

const transferMessageTitleStyle = {
  ...mapping["forms/label/1-default"],
  margin: "21px 0 0 24px",
  color: mapping["Color_Basic_Primary"],
};
export { AlertBasicInfo };
